
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiFilter, mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { dateDotNotationToDash } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'

import {
  useDeleteEquipment,
  useGetEquipmentList,
  useExportEquipmentList,
  useGetEquipmentListBasic,
} from '@/api/equipment'
import { useGetPersonsBasic } from '@/api/person'
import { useGetUsersBasic } from '@/api/user'

import { EquipmentOutput } from '@/api/types/equipment'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/administration/views/equipment/types'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { Rights } from '@/api/types/right'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'Equipment',
  components: {
    CommonTableView,
    CommonExportMenu,
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonFilesDialog: () => import('@/components/common/CommonFilesDialog.vue'),
    AddEditEquipmentDialog: () =>
      import('@/views/administration/views/equipment/components/AddEditEquipmentDialog.vue'),
    EquipmentListFilterBar: () =>
      import('@/views/administration/views/equipment/components/EquipmentListFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const TABLE_HEADERS = [
      {
        text: root.$t('administration.equipments.table.col.title.id'),
        value: 'id',
        sortable: false,
      },
      {
        text: root.$t('administration.equipments.table.col.title.person'),
        value: 'person.name',
        sortable: false,
      },
      {
        text: root.$t('administration.equipments.table.col.title.name'),
        value: 'name',
        sortable: true,
      },
      {
        text: root.$t('administration.equipments.table.col.title.type'),
        value: 'type',
        sortable: true,
      },
      {
        text: root.$t('administration.equipments.table.col.title.serialNumber'),
        value: 'serialNumber',
        sortable: true,
      },
      {
        text: root.$t('administration.equipments.table.col.title.description'),
        value: 'description',
        sortable: false,
      },
      {
        text: root.$t('administration.equipments.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('administration.equipments.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('administration.equipments.table.col.title.filesCount'),
        value: 'filesCount',
        sortable: false,
      },
      {
        text: root.$t('administration.equipments.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof EquipmentOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'issuedBy',
      'issuedOn',
      'actualReturn',
      'actualReturnBy',
      'plannedReturn',
      'inventoryItem',
    ]

    const isRowInfoDialogOpen = ref(false)

    const isFilesDialogOpen = ref(false)

    const activeEquipment = ref<EquipmentOutput | null>(null)

    function onClickInfo(equipment: EquipmentOutput): void {
      activeEquipment.value = { ...equipment }

      isRowInfoDialogOpen.value = true
    }

    function onClickFiles(equipment: EquipmentOutput): void {
      activeEquipment.value = { ...equipment }

      isFilesDialogOpen.value = true
    }

    const isAddEditModalOpen = ref(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onClickEdit([_, { item: equipment }]: [void, { item: EquipmentOutput }]): void {
      if (hasSufficientRights(Rights.EQUIPMENT_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isAddEditModalOpen.value = true

        activeEquipment.value = { ...equipment }
      }
    }

    const isDeleteDialogOpen = ref(false)

    function onClickDelete(equipment: EquipmentOutput): void {
      activeEquipment.value = { ...equipment }

      isDeleteDialogOpen.value = true
    }

    const { deleteEquipment } = useDeleteEquipment()

    async function onDelete(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteEquipment(activeEquipment.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false

      activeEquipment.value = null

      debouncedCb.value()
    }

    function onCloseAddEditDeleteInfoFilesDialog(): void {
      isAddEditModalOpen.value = false

      isDeleteDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isFilesDialogOpen.value = false

      activeEquipment.value = null
    }

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!persons.value?.length && !equipmentListBasic.value?.length) {
          getPersonsBasic()
          getEquipmentListBasic()
        }

        getUsersBasic()
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const { exec: getPersonsBasic, data: persons, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()

    const {
      exec: getEquipmentListBasic,
      data: equipmentListBasic,
      isLoading: isLoadingEquipmentListBasic,
    } = useGetEquipmentListBasic()

    const { exec: getUsersBasic } = useGetUsersBasic()

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.EquipmentIds,
        value: null,
        label: root.$t('administration.equipments.filters.equipmentIds'),
        items: computed(() => equipmentListBasic.value ?? []),
        isLoading: computed(() => isLoadingEquipmentListBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.PersonIds,
        value: null,
        label: root.$t('administration.equipments.filters.personIds'),
        items: computed(() => persons.value ?? []),
        isLoading: computed(() => isLoadingPersonsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Name,
        value: null,
        label: root.$t('administration.equipments.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Type,
        value: null,
        label: root.$t('administration.equipments.filters.type'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.SerialNumber,
        value: null,
        label: root.$t('administration.equipments.filters.serialNumber'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.PlannedReturnFrom,
        value: null,
        label: root.$t('administration.equipments.filters.plannedReturnSince'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.PlannedReturnTo,
        value: null,
        label: root.$t('administration.equipments.filters.plannedReturnUntil'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )
    setSort(vuetifyTableOptions, { by: 'name', desc: false })

    const {
      exec: getEquipmentList,
      data: equipmentList,
      isLoading: isLoadingEquipmentList,
      paginationResponse,
    } = useGetEquipmentList()

    const totalEquipments = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getEquipmentList({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const { exportEquipmentList, isLoading: isLoadingExport } = useExportEquipmentList()

    async function onExportEquipmentList(dateRange: { startMonth: string; endMonth: string }) {
      try {
        await exportEquipmentList({
          ...filterFieldsObject.value,
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: equipmentNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<EquipmentOutput>(equipmentList, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: equipmentTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<EquipmentOutput>(equipmentList, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiPlus,
        mdiFilter,
        mdiChevronDown,
        mdiChevronUp,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        equipmentList,
        isLoadingEquipmentList,

        isAddEditModalOpen,

        isRowInfoDialogOpen,
        activeEquipment,

        isTasksDialogOpen,
        equipmentTasks,
        tasksEntityId,

        isNotesDialogOpen,
        equipmentNotes,
        notesEntityId,

        isDeleteDialogOpen,

        isFilterDropdownOpen,
        vuetifyTableOptions,
        totalEquipments,

        isLoadingExport,

        isFilesDialogOpen,
      },
      listeners: {
        onClickInfo,

        onClickEdit,

        onClickDelete,
        onDelete,

        onExportEquipmentList,

        onCloseAddEditDeleteInfoFilesDialog,

        onOpenTasksDialog,
        onReloadTasks,

        onOpenNotesDialog,
        onReloadNotes,

        onToggleFilterDropdown,

        onClickFiles,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
