enum FILTER_FIELD_KEY {
  EquipmentIds = 'ids',
  PersonIds = 'personIds',
  Name = 'name',
  Type = 'type',
  SerialNumber = 'serialNumber',
  PlannedReturnFrom = 'plannedReturnFrom',
  PlannedReturnTo = 'plannedReturnTo',
}

export { FILTER_FIELD_KEY }
