import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  EquipmentOutput,
  EquipmentId,
  EquipmentInput,
  UseCreateEquipment,
  UseDeleteEquipment,
  UseGetEquipmentList,
  UseUpdateEquipment,
  UseExportEquipmentList,
  UseGetEquipmentListBasic,
} from '@/api/types/equipment'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'equipments'

function useGetEquipmentList(): UseGetEquipmentList {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetEquipmentList['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetEquipmentListBasic(): UseGetEquipmentListBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateEquipment(): UseCreateEquipment {
  const axios = useAxios<PromiseType<ReturnType<UseCreateEquipment['createEquipment']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createEquipment(data: EquipmentInput): Promise<EquipmentOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createEquipment,
  }
}

function useUpdateEquipment(): UseUpdateEquipment {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateEquipment['updateEquipment']>>>({ method: 'PUT' })

  function updateEquipment(id: EquipmentId, data: EquipmentOutput): Promise<EquipmentOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateEquipment,
  }
}

function useDeleteEquipment(): UseDeleteEquipment {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteEquipment['deleteEquipment']>>>({ method: 'DELETE' })

  function deleteEquipment(id: EquipmentId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteEquipment,
  }
}

function useExportEquipmentList(): UseExportEquipmentList {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportEquipmentList(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportEquipmentList,
  }
}

export {
  useGetEquipmentList,
  useGetEquipmentListBasic,
  useCreateEquipment,
  useUpdateEquipment,
  useDeleteEquipment,
  useExportEquipmentList,
}
